import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import "./MainContent.css";
import Header from "./Header";
import HeroSection from "./HeroSection";
import VideoSection from "./VideoSection";
import BenefitsSection from "./BenefitsSection";
import AppSection from "./AppSection";
import Testimonial from "./Testimonial";
import OfferSection from "./OfferSection";
import BestSection from "./BestSection";
import TCSection from "./TCSection";
import Footer from "./Footer";
import Page404 from "./Page404";

import {
  Container,
  Backdrop,
  CircularProgress,
  Skeleton,
  useMediaQuery,
} from "@mui/material";
import AppPopup from "./AppPopup";
import EnquiryPopUp from "./EnquiryPopUp";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import Info from "./Info";
import Error500 from "./Error500";

const MainContent = () => {
  const isMobile = useMediaQuery("(max-width:800px)");

  function extractTextFromHTML(htmlString) {
    // Create a new DOM element
    const tempElement = document.createElement("div");

    // Set the HTML content
    tempElement.innerHTML = htmlString;

    // Extract and return the text content
    return tempElement.innerText || tempElement.textContent || "";
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: "#81385B",
        fontFamily: "Montserrat",
      },
    },
  });

  const benefitsRef = useRef(null);

  const handleScrollFindOutMore = () => {
    if (benefitsRef.current) {
      benefitsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const viewportMeta = document.querySelector('meta[name="viewport"]');
      const desiredWidth = 1440; // This is the width your design is optimized for
      const screenWidth = window.outerWidth;
      const scale = screenWidth / desiredWidth;
      if (screenWidth < 800) {
        const mobileScale = screenWidth / 500;
        viewportMeta.setAttribute(
          "content",
          `width=500, initial-scale=1,user-scalable=yes ,initial-scale=${mobileScale}`
        );
      } else {
        if (screenWidth < desiredWidth) {
          // If the screen is smaller than your desired width, set a scaled viewport
          viewportMeta.setAttribute(
            "content",
            `width=${desiredWidth}, initial-scale=${scale}`
          );
        } else {
          // For larger screens, reset to normal scaling
          viewportMeta.setAttribute(
            "content",
            "width=device-width, initial-scale=1"
          );
        }
      }
    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Clean up
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [pitLandingPageInfo, setPITLandingPageInfo] = useState("");
  const [appRegSuccess, setAppRegistrationSuccess] = useState(false);
  const [error500, setError500] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [currentPath, setCurrentpath] = useState(
    window.location.pathname.replace(/\/$/, "")
  );

  const [pageNotFound, setPageNotFound] = useState(false);

  const [appRegistrationPopup, setAppRegistrationPopup] = useState(false);

  const [displayEnquiryPopup, setDisplayEnquiryPopup] = useState(false);

  const handleEnquiryPopUpOpen = () => {
    setDisplayEnquiryPopup(true);
  };

  const handleEnquiryPopUpClose = (event, reason) => {

    if (reason !== "backdropClick") {
      setDisplayEnquiryPopup(false);
    }
    
  };

  const handleAppRegistrationPopupOpen = () => {
    setAppRegistrationPopup(true);
  };

  const handleAppRegistrationPopupClose = (event, reason) => {

    if (reason !== "backdropClick") {
      setAppRegistrationPopup(false);
    }
    
  };

  useEffect(() => {
    

    const pathSegments = currentPath.split("/");

    if (pathSegments?.length === 1) {
      const defaultSubDomain =
        process.env.REACT_APP_DEFAULT_SUB_DOMAIN || "home";
      const newpath = `/` + defaultSubDomain;

      window.history.pushState({}, "", newpath);
      setCurrentpath(newpath);
    }

    if (pathSegments?.length > 3) {
      setPageNotFound(true);
    }

    const baseURL =
      process.env.REACT_APP_BACKEND_BASE_URL ||
      "https://vision-pit-backend-dot-seismic-helper-364013.nw.r.appspot.com";

    const paramValue = pathSegments[1];
    if (paramValue) {
      setLoading(true);
      fetch(`${baseURL}/affiliate_media/${paramValue}`)
        .then((response) => {
          if (response?.status === 404) {
            setPageNotFound(true);
            setLoading(false);
          }

          if (response?.status >= 500) {
            setError500(true);
            setLoading(false);
          }

          return response.json();
        })
        .then((data) => {
          setPITLandingPageInfo(data);
          if (pathSegments[2] && "success" === pathSegments[2]) {
            setAppRegistrationSuccess(true);
          } else {
            setAppRegistrationSuccess(false);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    } 
    // else {
    //   setLoading(false);
    //   setPageNotFound(true);
    // }
  }, [currentPath]);

  useEffect(() => {
    if (appRegSuccess) {
      setAppRegistrationPopup(false);
    }
  }, [appRegSuccess]);

  useEffect(() => {
    const handlePathChange = () => {
      setCurrentpath(window.location.pathname.replace(/\/$/, ""));
    };

    window.addEventListener("popstate", handlePathChange);

    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    window.history.pushState = function (...args) {
      originalPushState.apply(window.history, args);
      handlePathChange();
    };

    window.history.replaceState = function (...args) {
      originalReplaceState.apply(window.history, args);
      handlePathChange();
    };

    return () => {
      window.removeEventListener("popstate", handlePathChange);
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, []);

  const findAssest = (sortOrder) => {
    return pitLandingPageInfo?.landing_data?.assets?.filter(
      (asset) => asset?.sort_order === sortOrder
    )?.[0];
  };

  return (
    <main className="main-content">
      {isLoading ? (
        <Container style={{ maxWidth: "1440px", padding: "0px" }}>
          <Container style={{ maxWidth: "1196", padding: "0px" }}>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <Skeleton
              variant="rounded"
              className="header"
              style={{ maxwidth: "1196", height: "42px", marginBottom: "2rem" }}
            />
            <Skeleton
              variant="rounded"
              style={{
                maxwidth: "1196",
                height: "698px",
                marginBottom: "2rem",
              }}
            />
            <Skeleton
              variant="rounded"
              style={{
                maxwidth: "1196",
                height: "807px",
                marginBottom: "2rem",
              }}
            />
          </Container>
        </Container>
      ) : pageNotFound ? (
        <Info
          pageType={"404"} //pitLandingPageInfo={pitLandingPageInfo}
          theme={theme}
        />
      ) : appRegSuccess ? (
        <Info
          pageType={"success"}
          pitLandingPageInfo={pitLandingPageInfo}
          theme={theme}
        />
      ) : error500 ? (<Error500/>):(
        <>
          <Container style={{ maxWidth: "1440px", padding: "0px" }}>
            <Container style={{ maxWidth: "1300px", padding: "0px" }}>
              <Header
                headerInfo={pitLandingPageInfo?.landing_data?.pit_info}
                enquiryAction={handleEnquiryPopUpOpen}
              />

              <ThemeProvider theme={theme}>
                <AppPopup
                  open={appRegistrationPopup}
                  handleClose={handleAppRegistrationPopupClose}
                  pitId={
                    pitLandingPageInfo?.landing_data?.pit_info?.cms_subdomain
                  }
                  setAppRegistrationSuccess={setAppRegistrationPopup}
                />
                <EnquiryPopUp
                  open={displayEnquiryPopup}
                  handleClose={handleEnquiryPopUpClose}
                  pitId={
                    pitLandingPageInfo?.landing_data?.pit_info?.cms_subdomain
                  }
                />
              </ThemeProvider>
              <HeroSection
                heroInfo={findAssest(100)}
                pitInfo={pitLandingPageInfo?.landing_data?.pit_info}
                action={handleScrollFindOutMore}
                isMobile={isMobile}
                extractTextFromHTML={extractTextFromHTML}
              />
            </Container>
          </Container>
          <Container style={{ maxWidth: "1440px", padding: "0px" }}>
            <Container style={{ maxWidth: "1196px", padding: "0px" }}>
              <VideoSection
                ref={benefitsRef}
                videoSectionInfo={findAssest(150)}
                wistiaBlock={findAssest(200)}
                handleRegistration={handleAppRegistrationPopupOpen}
                isMobile={isMobile}
                extractTextFromHTML={extractTextFromHTML}
              />
              <BenefitsSection
                appBenefitsInfo={findAssest(300)}
                cards={findAssest(400)}
                isMobile={isMobile}
                extractTextFromHTML={extractTextFromHTML}
              />
              <AppSection
                appInfo={findAssest(600)}
                getAppNow={handleAppRegistrationPopupOpen}
                isMobile={isMobile}
                extractTextFromHTML={extractTextFromHTML}
              />
              <Testimonial testimonialInfo={findAssest(650)} />

              <OfferSection
                offersInfo={findAssest(700)}
                cards={findAssest(800)}
                partnersInfo={findAssest(1100)}
                getAppNow={handleAppRegistrationPopupOpen}
                isMobile={isMobile}
                extractTextFromHTML={extractTextFromHTML}
              />
            </Container>
          </Container>
          <BestSection
            info={findAssest(1200)}
            benefits={findAssest(1300)}
            isMobile={isMobile}
            extractTextFromHTML={extractTextFromHTML}
          />
          <TCSection
            enquiryAction={handleEnquiryPopUpOpen}
            tcInfo={pitLandingPageInfo?.landing_data?.pit_info}
            isMobile={isMobile}
            extractTextFromHTML={extractTextFromHTML}
          />

          <Footer
            linkInfo={findAssest(3000)}
            theme={theme}
            footerDesc={findAssest(2600)}
            franchiseMembership={findAssest(2500)}
            awards={findAssest(2400)}
          />
        </>
      )}
    </main>
  );
};

export default MainContent;
